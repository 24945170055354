import React, { useRef, useState, useEffect } from 'react';

const ImageLoader = ({
  className,
  placeholderAspectRatio,
  image,
  webp,
  smallImage,
  smallWebp,
  gifUrl,
  alt,
}) => {
  const [ isImgLoading, setIsImgLoading ] = useState(false);
  const [ awaitImgLoad, setAwaitImgLoad ] = useState(false);

  useEffect(() => {
    if (!isImgLoading) return;

    const timer = setTimeout(() => {
      setAwaitImgLoad(true);
    }, 500);

    // eslint-disable-next-line consistent-return
    return () => clearTimeout(timer);
  }, [ isImgLoading ]);
  const imgRef = useRef(null);

  useEffect(() => {
    if (imgRef.current && imgRef.current.complete) {
      setIsImgLoading(true);
    }
  }, [ image ]);

  return (
    <figure className={`${className}`}>
      {!awaitImgLoad && <div className='blank-placeholder' style={{ aspectRatio: `${placeholderAspectRatio}` }} />}
      <picture className={!awaitImgLoad ? 'img-absolute' : ''}>
        {(!gifUrl && smallWebp && smallImage) && (
          <>
            <source media='(max-width: 768px)' srcSet={smallWebp} type='image/webp' />
            <source media='(max-width: 768px)' srcSet={smallImage} type='image/png' />
          </>
        )}
        {!gifUrl
          && (
            <>
              <source srcSet={webp} type='image/webp' />
              <source srcSet={image} type='image/png' />
              <img
                ref={imgRef}
                src={image}
                alt={alt}
                style={{ opacity: `${awaitImgLoad ? 1 : 0}` }}
                onLoad={() => setIsImgLoading(true)}
              />
            </>
          )}
        {gifUrl
          && (
            <>
              <source srcSet={gifUrl} type='image/gif' />
              <img
                ref={imgRef}
                src={gifUrl}
                alt={alt}
                style={{ opacity: `${awaitImgLoad ? 1 : 0}` }}
                onLoad={() => setIsImgLoading(true)}
              />
            </>
          )}
      </picture>
    </figure>
  );
};

export default ImageLoader;
